import axios from "axios";
import { Constants } from "../../constants";
import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT } from "../constants/userConstants"
import { getUserFavorites } from "./favoritesActions";
import { getUserLikedPosts } from "./likesActions";

export const login = (email, password) => async (dispatch) => {

    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            Constants.ApiUrl + '/users/login', 
            {
                email,
                password,
            },
            config,
            )

        const config2 = {
            headers: {
                'Authorization': `Bearer ${data.token}`
            },
        }
        const userData = await axios.get(Constants.ApiUrl + '/users/' + data.id, config2);
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {data, userData: userData.data}
        })
        dispatch(getUserFavorites(data));
        dispatch(getUserLikedPosts(data));

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message        
        })
    }
}

export const logout = () => async (dispatch) => {
    dispatch({
        type: USER_LOGOUT,
    })
}
