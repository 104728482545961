import React, { useEffect } from "react";
import {
    Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Home from "./containers/home";
import Opportunities from "./containers/opportunities";
import DoGood from "./containers/do-good";
import Kids from "./containers/kids";
import ServiceRooms from "./containers/service-rooms";
import Coffee from "./containers/coffee";
import Projects from "./containers/projects";
import Login from "./containers/login";
import Register from "./containers/register";
import BlogPost from "./containers/blog-post";
import Social from "./containers/social";
import SocialExperience from "./containers/socialExperience";
import SocialSchedule from "./containers/socialSchedule";
import GeneralSchedule from "./containers/generalSchedule";
import Profile from "./containers/profile";
import Schedule from "./containers/schedule";
import Contact from "./containers/contact";
import SocialAttendance from "./containers/social-attendance";
import GeneralAttendance from "./containers/generalAttendance";
import Payments from "./containers/payments";
import ForgotPassword from "./containers/forgotPassword";
import ChangePassword from "./containers/changePassword";
import ServiceDetails from "./containers/service-details";
import ScheduleReview from "./containers/schedule-review";
import Reports from "./containers/profile/reports";
import PlansMainPage from './containers/plans-main-page';
import PlansPayment from './containers/plans-payment';
import ProfessionalDetails from "./containers/professional-details";
import Event from './containers/events/event';
import EventsPayment from './containers/events/event-payment';
import Events from './containers/events';
import GeneralPayment from "./containers/generalPayment";
import { useSelector, useDispatch } from "react-redux";
import history from './services/history'
import { Modal } from "antd";
import { getUserFavorites } from './redux/actions/favoritesActions'
import { getUserLikedPosts } from './redux/actions/likesActions';
import PlansDetails from "./containers/plans-details";
import Room from "./containers/rooms";
import ServiceSpace from "./containers/service-space";
import Space from "./containers/spaces";
import AdminInfos from './containers/profile/admin';
import HomeBlog from "./containers/home-blog";
import Sugestion from "./containers/sugestions";
import ScrollToTop from "./utils/ScrollToTop";
import PagesContent from "./containers/pages-content";
import Gallery from "./containers/gallery";

export default function Routes() {

    const userLogin = useSelector(state => state.userLogin);
    const dispatch = useDispatch();
    const { userInfo } = userLogin;
    const signed = userInfo ? true : false

    useEffect(() => {
        dispatch(getUserFavorites(userInfo));
        dispatch(getUserLikedPosts(userInfo));
    },[dispatch, userInfo])

    return (
        <Router history={history}>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/kids" component={Kids} />
                <Route path="/oportunidades" component={Opportunities} />
                <Route path="/solidariedade" component={DoGood} />
                <Route path="/social" component={Social} />
                <Route path="/contato" component={Contact} />
                <Route path="/galerias" component={Gallery} />
                <Route path="/galeria/:id" component={SocialExperience} />
                <Route path="/experiencias" component={SocialExperience} />
                <Route path="/agenda-social" component={SocialSchedule} />
                <Route path="/agenda-geral" component={GeneralSchedule} />
                <Route path="/atendimento-social" component={SocialAttendance} />
                <Route path="/atendimento-geral" component={GeneralAttendance}/>
                <Route path="/blog-post/:id" component={BlogPost} />
                <Route path="/espacos" component={ServiceSpace} exact/>
                <Route path="/salas" component={ServiceRooms} exact />
                <Route path="/coffee" component={Coffee} />
                <Route path="/projetos" component={Projects} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/change-password/:token" component={ChangePassword} />
                <Route path="/terapias/:serviceId/:name" component={ServiceDetails} />
                <Route path="/profissionais/:professionalId/:name" component={ProfessionalDetails} />
                <Route path="/review/:scheduleId" component={ScheduleReview} />
                <Route path="/planos/:planId" component={PlansDetails} />
                <Route path="/planos" component={PlansMainPage} exact />
                <Route path="/eventos/:eventId" component={Event} />
                <Route path="/eventos" component={Events} />
                <Route path="/salas/:id" component={Room} />
                <Route path="/espacos/:id" component={Space} />
                <Route path="/blog" component={HomeBlog} />
                <Route path="/paginas/:slug" component={PagesContent} />
            { signed ? (
                <Switch>
                    <Route path="/minha-conta" component={Profile} />
                    <Route path="/agenda" component={Schedule} />
                    <Route path="/relatorios" component={Reports} />
                    <Route path="/administracao" component={AdminInfos} />
                    <Route path="/pagamento-social" component={Payments} />
                    <Route path="/pagamento-geral" component={GeneralPayment} />
                    <Route path="/pagamento-planos" component={PlansPayment} />
                    <Route path="/pagamento-eventos" component={EventsPayment}/>
                    <Route path="/sugestoes" component={Sugestion}/>
                    <Route path="/" component={() => {
                       return <Redirect to='/' />}
                    } />
                </Switch>
            )
            :
            (   
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/registro" component={Register} />.
                    <Route path="/" component={() => {
                        Modal.warning({
                            title: "Ops!",
                            content: 'É preciso estar logado para acessar essa página.'
                        })
                       return <Redirect to='/login' />}
                    } />
               </Switch>
            )}
                
            </Switch>
        </Router>
    );
};
